import config from './config'
let md5 = require('js-md5')

export function validate(user, request) {
    // 1. 完全开放就不挂这个了
    // 2. guestOnly仅限游客访问
    if ( request.guestOnly ) {
        return !user
    }
    // 3. 游客除外都可以
    if ( request.noGuest ) {
        return user
    }
    // 4. 仅限外部用户（激活状态的）
    if ( request.unactivatedOnly ) {
        return user && !user.is_admin && !user.activated
    }
    // 4. 仅限外部用户（激活状态的）
    if ( request.userOnly ) {
        return user && !user.is_admin
    }
    // 5. 仅限专家
    if (request.expertOnly) {
        return user && !user.is_admin && user.is_expert// && user.activated
    }
    // 6. 管理员不算在内
    if (request.noAdmin) {
        return !user || !user.is_admin;
    }
    // 7. 仅限管理员
    if (request.adminOnly) {
        return user && user.is_admin && user.activated
    }
    // 8. 仅限超级管理员
    if (request.superOnly) {
        return user && user.is_admin && user.activated && user.is_super
    }
    // 9. 复合管理员权限
    if (request.$or) {
        if (!user) {
            return false
        }
        if (user.is_super) {
            return true
        }
        for(let i = 0; i < request.$or.length; i++) {
            if (!request.$or[i].action) {
                if (user.privileges[request.$or[i].resources]) {
                    return true
                }
            }
            else {
                if (user.privileges[request.$or[i].resources] && user.privileges[request.$or[i].resources][request.$or[i].action]) {
                    return true
                }
            }
        }
        return false
    }
    // 10. 单项权限，不限类型
    if (request.resources && !request.action) {
        if (!user || !user.is_admin || !user.privileges) {
            return false
        }
        return user.is_super || user.privileges[request.resources]
    }
    // 10. 单项权限
    if (request.resources && request.action) {
        if (!user || !user.is_admin || !user.privileges) {
            return false
        }
        return user.is_super || (user.privileges[request.resources] && user.privileges[request.resources][request.action])
    }
    return false
}

export function g () {
    let times1 = Math.ceil(Math.random() * 10)
    let times2 = Math.ceil(Math.random() * 10)
    let result = config.accessToken, date = new Date().format('yyyyMMdd')
    for(let i = 0; i < times1; i++) {
        result = md5(result)
    }
    for(let i = 0; i < times2; i++) {
        date = md5(date)
    }
    return result + date
}