import Vue from 'vue'
import App from './App.vue'
import router from './routers/index'
import ElementUI from 'element-ui'
import vueHeadful from 'vue-headful';
import VueGoodWizard from 'vue-good-wizard';
import config from './config'
import store from './store'
import { validate } from "./utils";
import 'element-ui/lib/theme-chalk/display.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/base.css';
import en from 'element-ui/lib/locale/lang/en'
import './assets/stylesheets/style.scss'
import locale from 'element-ui/lib/locale'
Vue.config.productionTip = false;

locale.use(en)

Vue.use(ElementUI);
Vue.use(VueGoodWizard);
Vue.component('vue-headful', vueHeadful);

Vue.directive('auth', {
    bind: function (el, binding, vnode) {
        // 根据权限设置入口开闭
        let user = vnode.context.$store.state.profile
        if (!validate(user, binding.value)) {
            el.parentNode && el.parentNode.removeChild(el)
        }
    },
    inserted: function (el, binding, vnode) {
        // 根据权限设置入口开闭
        let user = vnode.context.$store.state.profile
        if (!validate(user, binding.value)) {
            el.parentNode && el.parentNode.removeChild(el)
        }
    },
    update: function (el, binding, vnode) {
        // 根据权限设置入口开闭
        let user = vnode.context.$store.state.profile
        if (!validate(user, binding.value)) {
            el.parentNode && el.parentNode.removeChild(el)
        }
    },
})

Vue.config.silent = true;

new Vue({
    components: {
        App
    },
    store,
    router,
    render: h => h(App),
}).$mount('#app');
